/* Skills.css */

.skills-container {
    margin: 50px 10vb;
    padding-left: 10%;
    padding-right: 10%;
    background-color: #111;
    color: #fff;
    width: 80vb;
}

.section-title {
    color: #0f0;
    font-family: 'Courier New', monospace;
    font-size: 24px;
    text-align: center;
    margin-bottom: 20px;
}

.skill-carousel {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 200px;
    /* Adjust the height as per your preference */
    margin-top: 20px;
}

.skill {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 20px;
}

.skill-icon {
    font-size: 48px;
    color: #0f0;
    margin-bottom: 10px;
}

h3 {
    font-family: 'Courier New', monospace;
    font-size: 18px;
    color: #fff;
}