.work-container {

    margin: 50px 10vb;
    padding-left: 10%;
    padding-right: 10%;
    background-color: #111;
    color: #fff;
    width: 80vb;
}

.section-title {
    color: #fff;
    font-family: 'Courier New', monospace;
    font-size: 24px;
    margin-bottom: 1%;
}

.work-list {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1%;
}

.work-item {
    background-color: #111;
    padding: 1%;
    border-radius: 5px;
    width: 50vb;
    text-align: center;
}

.logo-container {
    margin-bottom: 1%;
}

.company-logo {
    width: auto;
    height: 100px;
    /* Adjust the height as needed */
    object-fit: contain;
}
.work-title {
    color: #0f0;
    font-family: 'Courier New', monospace;
    font-size: 18px;
    margin-bottom: 1vh;
}

.work-company {
    color: #00ff00;
    /* Change to the desired color for the company name */

    font-size: 14px;
    margin-bottom: 5px;
}

.work-description {
    color: #fff;
  
    list-style-type: none;
    font-size: 15px;
    text-align: center;
    margin: 1% 0;
    line-height: 2;
}

