body {
  font-family: 'YourSoothingFont', sans-serif;
    background-color: #000;
    margin: 0;
}
.navbar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: #000;
  padding: 10px 0;
  display: flex;
  justify-content: center;
  z-index: 100;
}
.navbar a {
  color: #0f0;
  font-size: 16px;
  text-decoration: none;
  padding: 10px 20px;
  margin: 0 10px;
}

.navbar a:hover {
  background-color: #0f0;
  color: #000;
  border-radius: 5px;
}


.section-title {
  color: #0f0;
  font-size: 24px;
  margin-bottom: 20px;
  text-align: center;
}
.app-container {
  margin: 70px 10%;
  background-color: #111;

  /* Adjust the margin to create space below the fixed navbar */
}

/* App.css */
.animation-container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  z-index: 999;
}

.animation-text {
  font-size: 48px;
  color: #fff;
  text-shadow: 0 0 10px rgba(255, 255, 255, 0.8);
  opacity: 0;
  animation: fadeIn 3s ease-in-out;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    filter: blur(10px);
  }

  50% {
    opacity: 1;
    filter: blur(0);
  }

  100% {
    opacity: 1;
    filter: blur(0);
  }
}

.hide-animation {
  animation: fadeOut 1s ease-in-out;
}

@keyframes fadeOut {
  0% {
    opacity: 1;
    filter: blur(0);
  }

  100% {
    opacity: 0;
    filter: blur(0);
  }
}
/* .navbar {
  position: sticky;
  top: 0;
  background-color: #000;
  padding: 10px 0;
  display: flex;
  justify-content: center;
  z-index: 100;
}

.navbar a {
  color: #0f0;

  font-size: 16px;
  text-decoration: none;
  padding: 10px 20px;
  margin: 0 10px;
}

.navbar a:hover {
  background-color: #0f0;
  color: #000;
  border-radius: 5px;
} */




/* .work-list {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
} */

/* .work-item {
  background-color: #111;
  padding: 20px;
  border-radius: 5px;
  width: 500px;
  text-align: center;
}

.work-title {
  color: #0f0;

  font-size: 20px;
  margin-bottom: 10px;
}

.work-description {
  color: #fff;
 
  font-size: 16px;
  margin-bottom: 10px;
} */





/* 
.work-list {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.work-item {
  background-color: #222;
  padding: 20px;
  border-radius: 5px;
  width: 500px;
  text-align: center;
}

.work-title {
  color: #0f0;
  
  font-size: 20px;
  margin-bottom: 10px;
}

.work-description {
  color: #fff;
  
  font-size: 16px;
  margin-bottom: 10px;
} */
