/* About.css */

.about-container {
    margin: 5% 10vb;
    padding: 10%;
    background-color: #111;
    color: #fff;
    width: 80vb;
}

.section-title {
    color: #0f0;
    font-size: 24px;
    text-align: center;
    margin-bottom: 2%;
}

.about-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.profile-picture {
    width: 200px;
    height: 200px;
    border-radius: 50%;
    object-fit: cover;
    margin-bottom: 2%;
}

.about-description {
   
    font-size: 16px;
    margin-bottom: 2%;
}
.linkedin-profile {
    display: flex;
    align-items: center;
    margin-top: 1%;
}

.linkedin-text {
    margin-right: 0.5%;
}

.linkedin-icon {
    color: #0077b5;
    font-size: 24px;
    padding: 1%;
}